<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="row">
			<div :class="showList ? 'col-md-6' : 'col-md-12'">
				<a-form class="mb-4" :form="form" @submit="handleSubmit">
					<div class="row">
						<div class="col-md-6">
							<a-form-item class="m0" label="Marca">
								<a-select
									showSearch
									option-filter-prop="children"
									:filter-option="filterOption"
									v-decorator="[
										'motorcycle_brand_id',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
									@change="onChangeBrand"
								>
									<a-select-option v-for="brand in motorcycleBrandList" :key="brand.id" :value="brand.id"> {{ brand.name }} </a-select-option>
									<div slot="notFoundContent">
										<a-button class="btn btn-block" @click="onAddBrand">Añadir nueva marca</a-button>
									</div>
								</a-select>
							</a-form-item>
						</div>
						<div class="col-md-6">
							<a-form-item class="m0" label="Modelo">
								<a-select
									showSearch
									option-filter-prop="children"
									:filter-option="filterOption"
									v-decorator="[
										'motorcycle_model_id',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
									:disabled="!form.getFieldValue('motorcycle_brand_id')"
								>
									<a-select-option v-for="model in motorcycleModelByBrandList" :key="model.id" :value="model.id"> {{ model.name }} </a-select-option>
									<div slot="notFoundContent">
										<a-button class="btn btn-block" @click="onAddModel">Añadir nuevo modelo</a-button>
									</div>
								</a-select>
							</a-form-item>
						</div>
						<div class="col-md-6">
							<a-form-item class="m0" label="Año">
								<a-input
									v-mask="'#####'"
									v-decorator="[
										'year',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
									autocomplete="off"
								/>
							</a-form-item>
						</div>
						<div class="col-md-6">
							<a-form-item class="m0" label="Cilindrada">
								<a-input v-mask="'####'" v-decorator="['displacement']" autocomplete="off" />
							</a-form-item>
						</div>
						<div class="col-md-6">
							<a-form-item class="m0" label="Placa">
								<a-input class="text-uppercase" v-decorator="['plate']" autocomplete="off" />
							</a-form-item>
						</div>
						<div class="col-md-6">
							<a-form-item class="m0" label="NIV">
								<a-input v-decorator="['niv']" autocomplete="off" />
							</a-form-item>
						</div>
					</div>
					<div class="row" v-show="showControls">
						<div class="col-md-12">
							<hr class="hrText" />
						</div>
						<div class="col-md-4 text-left">
							<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
						</div>
						<div class="col-md-8 text-right">
							<a-button class="btn btn-danger" v-if="showCancelButton" @click="onCancelEdit">Cancelar</a-button>
							<a-button icon="save" class="btn btn-success ml7" htmlType="submit" id="clientDetailMotorcycleComponent_submit">Guardar</a-button>
						</div>
					</div>
				</a-form>
			</div>
			<div class="col-md-6" v-if="showList">
				<a-collapse accordion>
					<a-collapse-panel
						v-for="(element, index) in actualClient.motorcycles_information"
						:key="index"
						:header="`${element.motorcycle_brand_name} - ${element.motorcycle_model_name} ${element.year} `"
					>
						<table class="table table-bordered table-condensed table-striped m0 tablePadding25">
							<tbody>
								<tr>
									<td width="35%"><b>Marca</b></td>
									<td>{{ element.motorcycle_brand_name }}</td>
								</tr>
								<tr>
									<td width="35%"><b>Modelo</b></td>
									<td>{{ element.motorcycle_model_name }}</td>
								</tr>
								<tr>
									<td width="35%"><b>Año</b></td>
									<td>{{ element.year }}</td>
								</tr>
								<tr>
									<td width="35%"><b>Cilindrada</b></td>
									<td>{{ element.displacement | replaceIfLogicalFalse('-') }}</td>
								</tr>
								<tr>
									<td width="35%"><b>Placa</b></td>
									<td class="text-uppercase">{{ element.plate | replaceIfLogicalFalse('-') }}</td>
								</tr>
								<tr>
									<td width="35%"><b>NIV</b></td>
									<td>{{ element.niv | replaceIfLogicalFalse('-') }}</td>
								</tr>
							</tbody>
						</table>
						<div class="row">
							<div class="col-md-12 text-right" style="padding: 10px 25px" v-if="isEditable">
								<a-button class="btn btn-danger" size="small" icon="delete" @click="onDeleteElement(index)" />
								<a-button class="btn btn-info ml7" size="small" icon="edit" @click="onEditElement(index)" />
							</div>
						</div>
					</a-collapse-panel>
				</a-collapse>
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import _ from 'lodash'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'

export default {
	name: 'clientDetailMotorcyclesComponent',
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
		showControls: {
			type: Boolean,
			default: true,
		},
		showList: {
			type: Boolean,
			default: false,
		},
		controlTaller: {
			type: Boolean,
			default: false,
		},
		clientData: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	directives: {
		mask,
	},
	computed: {
		...mapGetters('clients', ['actualClient']),
		...mapGetters('motorcycleBrand', ['motorcycleBrandList', 'spinnerLoaderLabel', 'spinnerLoader']),
		...mapGetters('motorcycleModels', ['motorcycleModelByBrandList', 'spinnerLoaderLabel', 'spinnerLoader']),
		hasClientId() {
			return !!utilities.objectValidate(this.actualClient, 'id', false)
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			showCancelButton: false,
			actualMotorcycleInformation: 0,
		}
	},
	mounted() {
		this.$store.dispatch('motorcycleBrand/GET_BRANDS', { controlTaller: this.controlTaller })
	},
	methods: {
		onCancel() {
			this.$router.replace('/pos/clients')
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					let payload = {
						...values,
					}
					if (this.showCancelButton) {
						payload.id = this.actualClient.motorcycles_information[this.actualMotorcycleInformation].id

						await this.$store.dispatch('clients/UPDATE_MOTORCYCLE_INFORMATION', {
							clientId: this.controlTaller ? this.clientData.id : this.actualClient.id,
							payload,
							controlTaller: this.controlTaller,
						})
					} else {
						await this.$store.dispatch('clients/CREATE_MOTORCYCLE_INFORMATION', {
							clientId: this.controlTaller ? this.clientData.id : this.actualClient.id,
							payload,
							controlTaller: this.controlTaller,
						})
					}
					this.onCancelEdit()
				}
			})
		},
		setLocalData(values) {
			this.form.setFieldsValue({
				motorcycle_brand_id: values.motorcycle_brand_id,
				motorcycle_model_id: values.motorcycle_model_id,
				year: values.year,
				displacement: utilities.objectValidate(values, 'displacement', ''),
				plate: utilities.objectValidate(values, 'plate', ''),
				niv: utilities.objectValidate(values, 'niv', ''),
			})
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		onChangeBrand(brand_id) {
			this.form.setFieldsValue({
				motorcycle_model_id: '',
			})

			this.$store.dispatch('motorcycleModels/GET_MODELS_BY_BRAND', { brand_id, controlTaller: this.controlTaller })
		},
		onAddBrand() {
			Swal.fire({
				title: 'Marcas de motocicleta',
				text: 'Ingresa el nombre de la marca a dar de alta',
				input: 'text',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				reverseButtons: true,
				inputAttributes: {
					autocorrect: 'off',
					autocomplete: 'off',
				},
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!value) {
							resolve('Ingresa el nombre de la marca a dar de alta')
						}
						resolve()
					})
				},
			}).then((result) => {
				if (result.isConfirmed) {
					let payload = {
						name: result.value,
					}
					this.$store.dispatch('motorcycleBrand/CREATE_BRAND', { payload, controlTaller: this.controlTaller })
				}
			})
		},
		onAddModel() {
			let brandID = Number(this.form.getFieldValue('motorcycle_brand_id'))
			Swal.fire({
				title: this.motorcycleBrandList.find((e) => e.id == brandID).name,
				text: 'Ingresa el nombre del modelo a dar de alta',
				input: 'text',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				reverseButtons: true,
				inputAttributes: {
					autocorrect: 'off',
					autocomplete: 'off',
				},
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!value) {
							resolve('Ingresa el nombre del modelo a dar de alta')
						}
						resolve()
					})
				},
			}).then((result) => {
				if (result.isConfirmed) {
					let payload = { brand_id: brandID, name: result.value }
					this.$store.dispatch('motorcycleModels/CREATE_MODEL', { payload, controlTaller: this.controlTaller })
				}
			})
		},
		onCancelEdit() {
			this.form.resetFields()
			this.showCancelButton = false

			if (this.controlTaller && this.visible) {
				this.$emit('ok')
			}
		},
		onEditElement(index) {
			let values = _.cloneDeep(this.actualClient.motorcycles_information[index])
			this.actualMotorcycleInformation = index
			this.showCancelButton = true
			this.setLocalData(values)
		},
		onDeleteElement(index) {
			let id = this.actualClient.motorcycles_information[index].id
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas eliminar el registro de motocicleta del cliente?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('clients/DELETE_MOTORCYCLE_INFORMATION', { clientId: this.actualClient.id, id })
				}
			})
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>